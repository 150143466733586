<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="listbox">
              <div class="name">自定义</div>
            </div>
            <div class="ts">{{zdytext}}</div>
            <div class="listbox2">
              <div v-if="zdyflag === '2'"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
              <div v-if="zdyflag === '1'"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>
            </div>        
          </div>
          <div v-if="zdyflag === '1'">
            <div class="item">
              <div class="listbox">
                <div class="name">
                  <span class="zdy">自定义房间</span>
                  <input type="text" v-model="roomname" class="input rr" placeholder="请输入房间名称" />                               
                </div>
              </div>     
            </div>
          </div>
          <div v-if="zdyflag === '2'">
            <div class="item">
              <div class="listbox">
                <div class="name">房间号规则</div>
              </div>
              <div class="listbox1">
                <div class="name">
                  <span @click="onDx(1)">
                    <img src="@/assets/dx1.png" v-if="qsdxflag === '0'" class="dxicon" />
                    <img src="@/assets/dx2.png" v-if="qsdxflag === '1'" class="dxicon" />
                  </span>
                  <span  @click="onDx(1)" class="txt">去除数字4 </span>
                </div>            
              </div>            
              <div class="listbox1">
                <div class="name">
                  <span @click="onDx(2)">
                    <img src="@/assets/dx1.png" v-if="jsdxflag === '0'" class="dxicon" />
                    <img src="@/assets/dx2.png" v-if="jsdxflag === '1'" class="dxicon" />
                  </span>
                  <span @click="onDx(2)" class="txt">去除数字7</span>
                </div>             
              </div>                               
            </div>
            <div class="item">
              <div class="listbox">
                <div class="name">房间号区间</div>
              </div>            
              <div class="listbox">
                <div class="name">
                  <span class="txt">～</span>
                  <input type="number" oninput="if(value.length > 3)value = value.slice(0, 3)" v-model="roomcode1" class="input ri r w" placeholder="" />               
                </div> 
              </div>   
              <div class="listbox">
                <div class="name">
                  <span class="txt">室</span>
                  <input type="number" oninput="if(value.length > 3)value = value.slice(0, 3)" v-model="roomcode2" class="input" placeholder="" />               
                </div> 
              </div>     
            </div>
          </div>
          
          <div class="item">
            <div class="listbox">
              <div class="name">               
                <span class="zdy">所在区域</span>
                <select class="form_select" v-model="selectQyItem" >
                    <option v-for="qyitem in qyitems" :key="qyitem.zone_code" :value="qyitem.zone_code">{{qyitem.zone_name}}</option>
                </select>
              </div>
            </div>
            <!--<div class="listbox1">
              <div class="name">
                <select class="form_select" v-model="selectItem" @change="selectFn($event)">
                    <option v-for="qyitem in qyitems" :key="qyitem.id" :value="qyitem.id">{{qyitem.name}}</option>
                </select>
              </div>             
            </div>
            <div class="listbox">
              <div class="name">
                <img src="@/assets/arrow.png" class="arrow" />
                <div class="text">+添加区域</div>   
              </div>           
            </div>-->        
          </div>
          <div class="item">
            <div class="listbox">
              <!--<div class="name">所在楼层</div>-->
              <div class="name">               
                <span class="zdy">所在楼层</span>
                <select class="form_select1" v-model="selectLcItem" >
                    <option v-for="lcitem in lcitems" :key="lcitem.id" :value="lcitem.id">{{lcitem.name}}</option>
                </select>
              </div>
            </div>       
          </div>
          <div class="item">
            <div class="listbox">
              <div class="name">房间类型</div>
            </div>
            <div class="listbox1">
              <div class="name">
                <select class="form_select2" v-model="selectHhItem" @change="selectRoomTypes($event)">
                  <option v-for="hhitem in hhitems" :key="hhitem.room_type" :value="hhitem.room_type">{{hhitem.type_name}}</option>
                </select>
              </div>             
            </div>            
            <div class="listbox" v-if="types === '1'">
              <div class="name">
                <span class="txt">张</span>
                <input type="number" v-model="num_bed" class="input ri" placeholder="输入数字" />               
              </div> 
            </div>       
          </div>
          <div class="item">
            <div class="listbox">
              <div class="name">房间设施</div>
            </div>
            <div class="listbox1">
              <div class="name">
                <span @click="onDx(5)">
                  <img src="@/assets/dx1.png" v-if="rsqflag === '1'" class="dxicon" />
                  <img src="@/assets/dx2.png" v-if="rsqflag === '0'" class="dxicon" />
                </span>
                <span @click="onDx(5)" class="txt">热水器 </span>
              </div> 
              <div class="name">
                <span @click="onDx(6)">
                  <img src="@/assets/dx1.png" v-if="wsjflag === '1'" class="dxicon" />
                  <img src="@/assets/dx2.png" v-if="wsjflag === '0'" class="dxicon" />
                </span>
                <span @click="onDx(6)" class="txt">卫生间</span>
              </div>            
            </div>            
            <div class="listbox1">
              <div class="name">
                <span @click="onDx(7)">
                  <img src="@/assets/dx1.png" v-if="ktflag === '1'" class="dxicon" />
                  <img src="@/assets/dx2.png" v-if="ktflag === '0'" class="dxicon" />
                </span>
                <span @click="onDx(7)" class="txt">空调</span>
              </div>  
              <div class="name">
                <span @click="onDx(8)">
                  <img src="@/assets/dx1.png" v-if="chflag === '1'" class="dxicon" />
                  <img src="@/assets/dx2.png" v-if="chflag === '0'" class="dxicon" />
                </span>
                <span @click="onDx(8)" class="txt">窗户</span>
              </div>           
            </div>        
          </div>
        
        </div>      
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mm" @click="submit">提交</div>   
          <div class="button1 primary mt" @click="goback">取消</div>      
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  created() {
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.dataload();
    this.qyload();
    this.roomload();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      selectQyItem: '',
      selectLcItem: '1',
      selectHhItem: '00000001',
      types: '0',
      num_bed: '1',
      state:false,
      qyitems: [],
      lcitems: [],
      hhitems: [],
      roomcode1: '1',
      roomcode2: '10',
      roomname: '',
      zdytext: '否',
      zdyflag: '2',
      qsdxflag: '1',
      jsdxflag: '1',
      rsqflag: '1',
      ktflag: '1',
      wsjflag: '1',
      chflag: '1'
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/boss/room_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
    },
    dataload() {
      for(var i = 1; i < 100; i++) {
        var arr  = { "id" : i+'', "name" : (i+'楼') };
        this.lcitems.push(arr);
      }
    },
    async qyload() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_selectzonelist');
      //this.qyitems = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.qyitems = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    async roomload() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_roomtypelist');
      //this.hhitems = data.data;
      var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
      this.hhitems = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
    },
    onKg() {
      if(this.zdyflag === '1'){
        this.zdyflag='2';
        this.zdytext='否';
      }else{
        this.zdyflag='1';
        this.zdytext='是';
      }     
    },
    onDx(flag) {
      if(flag==1){
        if(this.qsdxflag === '1'){
          this.qsdxflag='0';
        }else{
          this.qsdxflag='1';
        }
      }else if(flag==2){
        if(this.jsdxflag === '1'){
          this.jsdxflag='0';
        }else{
          this.jsdxflag='1';
        }
      }else if(flag==5){
        if(this.rsqflag === '1'){
          this.rsqflag='0';
        }else{
          this.rsqflag='1';
        }
      }else if(flag==6){
        if(this.wsjflag === '1'){
          this.wsjflag='0';
        }else{
          this.wsjflag='1';
        }
      }else if(flag==7){
        if(this.ktflag === '1'){
          this.ktflag='0';
        }else{
          this.ktflag='1';
        }
      }else if(flag==8){
        if(this.chflag === '1'){
          this.chflag='0';
        }else{
          this.chflag='1';
        }
      }      
    },   
    selectRoomTypes(e) {
      //Toast(e.target.value);
      this.types = this.hhitems[e.target.selectedIndex].types;
      this.num_bed = this.hhitems[e.target.selectedIndex].num_bed;
      //Toast(this.num_bed);
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    async submit() {
      if(this.state){
        return false;
      }
      this.state = true;
      if (isEmpty(this.selectQyItem)) {
        Toast('请选择区域!');
        this.state = false;
        return;
      }else if(isEmpty(this.selectLcItem)){
        Toast('请选择楼层!');
        this.state = false;
        return;
      }
      else if(isEmpty(this.selectHhItem)){
        Toast('请选择房间类型!');
        this.state = false;
        return;
      }
      let { data } = await this.axios.post(
          '/ctid/TldjService/dj_addroom',
        qs.stringify({
          roomfloor: this.selectLcItem,
          zonecode: this.selectQyItem,
          roomtype: this.selectHhItem,
          has_window: this.chflag,
          has_ac: this.ktflag,
          has_heater: this.rsqflag,
          has_toilet: this.wsjflag,
          zdytype: this.zdyflag,
          roomname: this.roomname,
          sname: this.roomcode1,
          ename: this.roomcode2,
          num_bed: this.num_bed,
          four: this.qsdxflag,
          seven: this.jsdxflag
        })
      );
      if (data.flag === '0') {
        this.state = false;
        Toast(data.message);
        this.$router.replace({path:'/boss/room_list', query:{type:this.type,unionid:this.unionid,srole:this.srole}});
      } else {
        this.state = false;
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.container {
  padding: 0px 0px 0px 0px;
}
.content {
  margin-top: 60px;
  padding: 10px 40px 50px 40px;
}
.listbox {
  flex: 1;
  overflow: hidden;
  .red {
    color: rgb(180, 4, 4);
  }   
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .zdy {
      float: left;
    }
    .input {
      vertical-align: middle;
      width: 125px;
      border: 0;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      float: right;
      &.rr {
        margin-left: 72px;
        float: left;
        width: 399px;
      }
      &.ri {
        text-align: right;
      }
      &.r {
        margin-right: 50px;
      }
      &.w {
        width: 75px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
    .txt1 {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      display: block;
      //margin-left: 10px;
      margin-top: 8px;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
      color: rgb(136, 134, 134);
    }
    .form_select1 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 388px center no-repeat;
      background-size: 26px;
      width: 410px;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      text-align: right;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      float: right;
      direction: rtl;
      padding-right: 40px;
    }
    select:focus { outline: none; }

    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 388px center no-repeat;
      background-size: 26px;
      width: 410px;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      float: right;
      direction: rtl;
      padding-right: 40px;
    } 
    select:focus { outline: none; }
  }
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 35px;
    font-weight:800;
    margin-top: 52px;
    margin-left: 34px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
    } 
    .txt {
      vertical-align: middle;
      font-size: 25px;
      float: right;
      margin-left: 10px;
      margin-right: 10px;
      color: rgb(136, 134, 134);
    }
  }
  .unames {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 30px;
    margin-left: 36px;
  }
  .unames1 {
    height: 30px;
    line-height: 30px;
    font-size: 28px;
    color: #666666;
    margin-top: 10px;
    margin-left: 36px;
  }
  
}
.listbox1 {
  flex: 1;
  overflow: hidden;
  width: 50px;
  .name {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;   
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 6px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
    .arrow {
      float: right;
      vertical-align: middle;
      height: 40px;
    }
    .text {
      font-size: 25px;
      float: right;
    }
    .form_select {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/arrow_1.png') 98% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      direction: rtl;
      padding-right: 40px;
    } 
    select:focus { outline: none; }

    .form_select2 {
      /* 清除默认边框 */
      border: 0;
      /* 清除默认的箭头样式 */
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      /* 右侧添加小箭头的背景图 */
      background: url('/img/xl1.png') 90% center no-repeat;
      background-size: 26px;
      width: 100%;
      height: 60px;
      background-color: transparent;
      font-size: 25px;
      color: rgb(136, 134, 134);
      line-height: 60px;
      vertical-align: middle;
      margin-left: 15px;
    } 
    select:focus { outline: none; } 

  }  
  .name1 {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    font-size: 28px;
    .dxicon {
      width: 35px;
      height: 35px;
      vertical-align: middle;
      margin-right: 6px;
      &.ll {
        margin-left: 10px;
      }
    }
    .txt {
      vertical-align: middle;
      font-size: 25px; 
      color: rgb(136, 134, 134);    
    }
  }
}

.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}

.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}

.ts {
  height: 60px;
  width: 50px;
  line-height: 60px;
  overflow: hidden;
  font-size: 25px;
  display: block;
  float: right;
  color: rgb(136, 134, 134);
}
.listbox2 {
  overflow: hidden;
  .unames {
    height: 60px;
    line-height: 60px;
    font-size: 28px;
    color: #c90c0c;
    margin-top: 22px;
    margin-right: 34px;
    float: right;
  }
  .iconkg {
    width: 80px;
    display: block;
    float: right;
  }  
     
}
</style>